<template>
  <div>
    <el-card>
      <el-row>
        <el-col :span="8">
          <el-input placeholder="请输入表名进行搜索" v-model="noname" clearable>
            <el-button slot="append" icon="el-icon-search" @click="onSearch">搜索</el-button>
          </el-input>
        </el-col>
        <el-col :span="8">
          <div></div>
        </el-col>
        <el-col :span="6" style="float: right;text-align: right">
          <el-button type="success" icon="el-icon-circle-plus-outline" round @click="onAddUnit">新增</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-table
              :data="tableData"
              stripe
              border
              style="width: 100%">
            <el-table-column type="index"></el-table-column>
            <el-table-column prop="Tablename" label="表名"></el-table-column>
            <el-table-column prop="Fieldname" label="编码前缀名"></el-table-column>
            <el-table-column prop="Fieldno" label="编码后缀数"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-tooltip effect="light" content="编辑" placement="top" :hide-after="hideafter">
                  <el-button
                      type="primary"
                      icon="el-icon-edit-outline"
                      circle
                      @click="handleEdit(scope.$index, scope.row)">
                  </el-button>
                </el-tooltip>
                <el-tooltip effect="light" content="删除" placement="top" :hide-after="hideafter">
                  <el-button
                      style="margin-left: 30px"
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click="handleDelete(scope.$index, scope.row)">
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-card>

    <!--新增 弹出框操作begin-->
    <el-dialog
        title="新增表编码"
        :visible.sync="dialogVisible"
        width="40%"
        @close="oncloseadd">
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
        <el-form-item label="表名" prop="tablename">
          <el-input v-model="addForm.tablename"></el-input>
        </el-form-item>
        <el-form-item label="编码前缀名" prop="fieldname">
          <el-input v-model="addForm.fieldname"></el-input>
        </el-form-item>
        <el-form-item label="编码后缀数" prop="fieldno">
          <el-input v-model="addForm.fieldno"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="onsave">确 定</el-button>
  </span>
    </el-dialog>
    <!--新增 弹出框操作end-->

    <!--编辑 弹出框操作begin-->
    <el-dialog
        title="修改表编码"
        :visible.sync="editdialogVisible"
        width="40%"
        @close="oncloseedit">
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
        <el-form-item label="表名" prop="tablename">
          <el-input v-model="editForm.tablename"></el-input>
        </el-form-item>
        <el-form-item label="编码前缀名" prop="fieldname">
          <el-input v-model="editForm.fieldname"></el-input>
        </el-form-item>
        <el-form-item label="编码后缀数" prop="fieldno">
          <el-input v-model="editForm.fieldno"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="editdialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="onedit">确 定</el-button>
  </span>
    </el-dialog>
    <!--编辑 弹出框操作end-->
  </div>
</template>

<script>
import {AddSysNo, DeleteSysNo, EditSysNo, GetAllSysNoList, GetSearchSysNoList} from "@/network/user";

export default {
  name: "Number",
  data(){
    //自定义校验order字段
    let checkfieldno = (rule, value, callback) => {
      if (!value)
      {
        return callback(new Error('编码数字不能为空'));
      }
      const regnum =/^[1-9]\d*$/
      if (!regnum.test(value)) {
        callback(new Error('请输入数字值'));
      } else {
        callback();
      }
    };
    return{
      noname:'',
      tableData:[],
      hideafter:3000,
      //新增
      addForm:{
        tablename:'',
        fieldname:'',
        fieldno:''
      },
      //新增用户字段验证规则
      addFormRules:{
        tablename: [
          { required: true, message: '请输入表名称', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3 到 20 个字', trigger: 'blur' }
        ],
        fieldname: [
          { required: true, message: '请输入编码前缀名称', trigger: 'blur' },
          { min: 2, max: 4, message: '长度在 2 到 4 个字', trigger: 'blur' }
        ],
        fieldno: [
          { validator: checkfieldno, trigger: 'blur' }
        ]
      },
      //是否显示新增对话框
      dialogVisible:false,
      //编辑部门
      editForm:{
        tablename:'',
        fieldname:'',
        fieldno:'',
        id:''
      },
      //编辑用户字段验证规则
      editFormRules:{
        tablename: [
          { required: true, message: '请输入表名称', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3 到 20 个字', trigger: 'blur' }
        ],
        fieldname: [
          { required: true, message: '请输入编码前缀名称', trigger: 'blur' },
          { min: 2, max: 4, message: '长度在 2 到 4 个字', trigger: 'blur' }
        ],
        fieldno: [
          { validator: checkfieldno, trigger: 'blur' }
        ]
      },
      //是否显示编辑对话框
      editdialogVisible:false,
    }
  },
  created() {
    this.init()
  },
  methods:{
    init(){
      GetAllSysNoList(this.$store.state.token).then(res => {
        console.log(res);
        this.tableData =res.data
      }).catch(err => {
        console.log(err);
      })
    },
    onSearch(){
      if (this.noname !== ''){
        GetSearchSysNoList(this.$store.state.token,this.noname).then(res => {
          this.tableData =res.data
        }).catch(err => {
          console.log(err);
        })
      }else{
        this.init()
      }
    },
    onAddUnit(){
      this.dialogVisible=true
    },
    handleEdit(index,row){
      this.editdialogVisible =true
      this.editForm.fieldname =row.Fieldname
      this.editForm.tablename =row.Tablename
      this.editForm.fieldno =row.Fieldno
      this.editForm.id =row.Id
    },
    handleDelete(index,row){
      this.$confirm('此操作将删除该编码数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        DeleteSysNo(this.$store.state.token,row.Id).then(res => {
          if (res.data===true) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }else{
            this.$message({
              type: 'warning',
              message: '删除失败!'
            });
          }
          //刷新数据
          if (this.noname !== '')
          {
            GetSearchSysNoList(this.$store.state.token,this.noname).then(res => {
              this.tableData =res.data
            }).catch(err => {
              console.log(err);
            })
          }else
          {
            this.init()
          }
        }).catch(err => {
          console.log(err);
        })
      }).catch(() => {

      });
    },
    //关闭新增对话框，清除验证
    oncloseadd(){
      this.$refs.addFormRef.resetFields()
    },
    //新增按钮，点击保存
    onsave(){
      this.$refs.addFormRef.validate(call=>{
        if (!call) return
        let frmDate = new FormData();
        frmDate.append('tablename', this.addForm.tablename);
        frmDate.append('fieldname', this.addForm.fieldname);
        frmDate.append('fieldno', this.addForm.fieldno);
        AddSysNo(this.$store.state.token,frmDate).then(res => {
          if (res.isadd===false) {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }else {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.dialogVisible=false
            if (this.noname !== '')
            {
              GetSearchSysNoList(this.$store.state.token,this.noname).then(res => {
                this.tableData =res.data
              }).catch(err => {
                console.log(err);
              })
            }else
            {
              this.init()
            }
          }
        }).catch(err => {
          console.log(err);
        })
      })
    },
    //关闭编辑对话框，清除验证
    oncloseedit(){
      this.$refs.editFormRef.resetFields()
    },
    onedit(){
      this.$refs.editFormRef.validate(call=>{
        if (!call) return
        let frmDate = new FormData();
        frmDate.append('tablename', this.editForm.tablename);
        frmDate.append('fieldname', this.editForm.fieldname);
        frmDate.append('fieldno', this.editForm.fieldno);
        frmDate.append('noid', this.editForm.id);
        EditSysNo(this.$store.state.token,frmDate).then(res => {
          if (res.isadd===false) {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }else {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.editdialogVisible=false
            //刷新数据
            if (this.noname !== '')
            {
              GetSearchSysNoList(this.$store.state.token,this.noname).then(res => {
                this.tableData =res.data
              }).catch(err => {
                console.log(err);
              })
            }else
            {
              this.init()
            }
          }
        }).catch(err => {
          console.log(err);
        })
      })
    },
  }
}
</script>

<style scoped>
.el-table{
  margin: 20px 0;
}
.el-select{
  width: 100%;
}
</style>